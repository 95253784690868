import animationData from '../animations/logo.json';

window.addEventListener('DOMContentLoaded', function () {

    const dialogPrivacyPolicy = document.querySelector('#privacy-policy');

    document.querySelector('[data-privacy-policy]').addEventListener('click', () => {
        dialogPrivacyPolicy.show();
    });

    document.querySelector('[data-close-dialog]').addEventListener('click', () => {
        dialogPrivacyPolicy.close();
    });

    dialogPrivacyPolicy.addEventListener('click', ev => {
        if (ev.target.closest('.text-content')) return;
        dialogPrivacyPolicy.close();
    });

    document.addEventListener('keydown', ev => {
        if (ev.key === 'Escape') {
            dialogPrivacyPolicy.close();
        }
    });

    bodymovin.loadAnimation({
        container: document.getElementById('lottie-player'),
        renderer: 'svg',
        loop: false,
        autoplay: true,
        animationData: animationData
    });

});
